<script setup lang="ts">
import {Carousel, Navigation, Slide} from "vue3-carousel";

const props = defineProps<{
    categoryId: string;
    config: any;
}>();

const {t} = useI18n();
const category = inject('category');
const indexName = useRuntimeConfig().public.algoliaDev.categoryIndexName;
const filters = 'active:true AND parent:' + props.categoryId;
const {data, error} = await useAsyncAlgoliaSearch({
    indexName: indexName,
    query: '',
    requestOptions: {
        filters: filters
    }
});
const subcategories = ref(data.value?.hits || []);
const imgWidth = ref('150');
const subcategoryCarousel = ref(null);
const isSlider = ref(true);

function getRelativeUrl(absoluteUrl) {
    const url = new URL(absoluteUrl);
    return url.pathname;
}

onMounted(() => {
    if (data.value.hits) {
        subcategories.value = Object.values(data.value.hits.filter((category) => category.media.url))
    }
});

const title = props.config.title?.value ?? t('listing.popularCategories')

watch(() => subcategoryCarousel.value?.data.config.itemsToShow, (value) => {
    isSlider.value = (subcategories.value.length > value);
    if (value < 4) {
        imgWidth.value = '100';
    } else {
        imgWidth.value = '150';
    }
});
</script>
<template>
    <div class="carousel-element container pt-6">
        <div v-if="subcategories.length > 0" class="pt-2">
            <h4 class="ml-5 mb-5">
                {{ title }}
            </h4>
            <carousel
                :items-to-show="3"
                :items-to-scroll="1"
                :wrap-around="isSlider"
                :mouse-drag="isSlider"
                :touch-drag="isSlider"
                :breakpoints="props.config.breakpoints"
                snap-align="start"
                ref="subcategoryCarousel"
            >
                <slide v-for="subcategory in subcategories" :key="subcategory.objectID">
                    <div class="carousel__item w-full h-full">
                        <a :href="getRelativeUrl(subcategory.url)" class="flex flex-col items-center">
                            <img
                                :src="useDynamicThumbnail(subcategory.media.url, 150, 200)"
                                :alt="subcategory.name"
                                :width="imgWidth"
                                class="img-fluid"
                                loading="lazy"
                            />
                            <p class="text-center">{{subcategory.name}}</p>
                        </a>
                    </div>
                </slide>
                <template #addons>
                    <template v-if="isSlider">
                        <navigation />
                    </template>
                </template>
            </carousel>
        </div>
    </div>
</template>
